import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { fetchFirebaseAssets, paymentMethodsData } from "../utils/storage";

export const FormasPago = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  const fetchImageUrls = useCallback(async () => {
    const imageURLs = await Promise.all(
      paymentMethodsData.map(async (pm) => ({
        ...pm,
        image: await fetchFirebaseAssets(pm.image),
      }))
    );

    setPaymentMethods(imageURLs);
  }, []);

  useEffect(() => fetchImageUrls(), [fetchImageUrls]);

  return (
    <ContPay>
      {paymentMethods.map((Pagos) => (
        <ImgCont key={Pagos.id}>
          <img src={Pagos.image} alt={Pagos.alt} />
        </ImgCont>
      ))}
    </ContPay>
  );
};

const ContPay = styled.div`
  display: flex;
  max-width: 1140px;
  margin: 24px auto 50px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ImgCont = styled.div`
  padding: 0 15px;

  @media (max-width: 1200px) {
    width: 33%;
    padding: 7px 25px;
    img {
      width: 100%;
      max-width: 120px;
      margin: 13px auto;
      display: block;
    }
  }
`;
