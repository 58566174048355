export function getElement(data, key = 0) {
  let arrayData = [];
  data.forEach((doc) => {
    arrayData.push(doc.data());
  });
  return arrayData[key];
}

export var validateForm = {
  rut: function (rutCompleto = '') {
    rutCompleto = rutCompleto.includes('-')
      ? rutCompleto
      : rutCompleto.substring(0, rutCompleto.length - 1) +
        '-' +
        rutCompleto.charAt(rutCompleto.length - 1);

    console.log(rutCompleto);

    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
      console.log('return false, formato');
      return false;
    }

    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv === 'K') digv = 'k';

    return validateForm.dv(rut).toString() === digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : 'k';
  },
  email: function (e) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(e).toLowerCase());
  },
  phoneNumber: function (n) {
    let number = parseInt(n);
    return number.toString().length === 9 && number > 0;
  },
  identifier: function (i, t) {
    console.log('fn identifier ...');
    if (t === 'RUT') return validateForm.rut(i);
    if (t === 'PHONE') return validateForm.phoneNumber(i);
    return false;
  },
};
