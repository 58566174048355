const CONFIG_DEV = {
  apiKey: "AIzaSyDpPAyUgb50D5CtXFUHGOga71eeBiQGEgE",
  authDomain: "tenpo-rf-test.firebaseapp.com",
  projectId: "tenpo-rf-test",
  storageBucket: "tenpo-rf-test.appspot.com",
  messagingSenderId: "187569322463",
  appId: "1:187569322463:web:8ba295491ac91da21b6c6a",
  measurementId: "G-739QDN8GGC",
};

const CONFIG_PROD = {
  apiKey: "AIzaSyDsbptUuTUYCYWo6ZigD-MDcO1VHgOCDY8",
  authDomain: "tenpo-rf-3f0cd.firebaseapp.com",
  projectId: "tenpo-rf-3f0cd",
  storageBucket: "tenpo-rf-3f0cd.appspot.com",
  messagingSenderId: "357586299376",
  appId: "1:357586299376:web:b718eb68f1ffefa9b69535",
};

const firebaseConfig = process.env?.REACT_APP_ENV === "prod" ? CONFIG_PROD : CONFIG_DEV;

// eslint-disable-next-line import/no-anonymous-default-export
export default firebaseConfig;
