import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import firebaseConfig from "../firebase-config";

export const fetchFirebaseAssets = (path) => {
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  try {
    return getDownloadURL(ref(storage, path));
  } catch (error) {
    console.warn(`Error fetching asset from Firebase on path ${path}`, error);
  }
};

export const paymentMethodsData = [
  {
    id: "1",
    image: "payment-methods/1.png",
    alt: "Pagar con webpay, tarjeta de crédito y débito",
  },
  {
    id: "2",
    image: "payment-methods/2.png",
    alt: "Pagar con Red compra",
  },
  {
    id: "4",
    alt: "Pagar con one pay",
    image: "payment-methods/4.png",
  },
  {
    id: "6",
    alt: "Pagar con Red compra prepago",
    image: "payment-methods/6.png",
  },
  {
    id: "7",
    image: "payment-methods/7.png",
    alt: "Pagar con Banco Estado",
  },
];

export const help = [
  {
    order: 1,
    text: "Si realizaste una recarga y tuviste un resultado exitoso con el comprobante de pago pero el monto no llegó, por favor verifica en primer lugar si recargaste al número correcto. Si el número es correcto y existió una caída en el servicio, existe un plazo máximo de 72 hrs hábiles para que tu dinero retorne hasta tu cuenta.",
    title: "Aún no recibes tu recarga y se realizó un pago exitoso",
  },
  {
    text: "En el caso que apareciera error en la transacción y que igualmente se descontara dinero de tu cuenta, el operador tiene un plazo de 1 hora y máximo de 72 hrs hábiles para el retorno del dinero. Recarga Fácil no hace abonos de dinero por lo que es necesario que esperes ese plazo de ejecución.",
    order: 2,
    title: "Recibiste mensaje de error y se descontó dinero en tu cuenta",
  },
  {
    title: "Te equivocaste en ingresar el número de recarga",
    text: "Si te equivocaste al ingresar el número y realizaste una recarga exitosa, no podemos devolverte el dinero. Recomendamos que te contactes directamente con tu compañía. No podemos asegurarte que se efectúe la devolución y/o anulación.",
    order: 3,
  },
  {
    text: "Si tu recarga fue fallida, no recibiste tu monto y ya han pasado más de las 72 hrs hábiles, entonces por favor comunícate con nosotros a través de alguna de nuestras redes sociales o a través del formulario de contacto para que podamos validar si corresponde una devolución manual. Es importante saber que Recarga Fácil no hace abonos, sino que anulamos la carga inicial.",
    order: 4,
    title: "Esperaste 72 horas y no se ha realizado la devolución",
  },
];

export const tyc = {
  title:
    "Condiciones Generales de Uso, Privacidad y Seguridad Sitio Web RecargaFácil.cl de Tenpo",
  content:
    'El presente instrumento (en adelante, las "Condiciones Generales") será aplicable a los usuarios y clientes (en adelante e indistintamente, el "Usuario" o los "Usuarios") que accedan a los sitios Web (en adelante e indistintamente, el "Sitio Web" o los "Sitios Web") de Tenpo SpA. o cualquiera de sus empresas relacionadas (en adelante e indistintamente "Tenpo").',
  footer:
    "Si el Usuario no está de acuerdo con estas Condiciones Generales, deberá abstenerse de acceder y hacer uso de los Sitios Web.",
};
