import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { validateForm } from "../utils/Utils";
import gs from "../components/services/Global";
import { Steps } from "./globales/Steps";
import * as c from "./globales/Recarga/Styles";
import { Promociones } from "./Promociones";
import { FormasPago } from "./FormasPago";
import Container from "./globales/Container";
import { AppContext } from "../providers/FormContext";

import Tabs from "react-responsive-tabs";

import "react-responsive-tabs/styles.css";
import { fetchFirebaseAssets } from "../utils/storage";

export default function Recarga() {
  function renderProducts(o) {
    return Object.entries(o).map(([key, value], i) => {
      return (
        <c.Li
          selected={form.name === value.name}
          name={value.code}
          imageURL={value.imageURL}
          onClick={() => {
            document.getElementById("form").reset();
            let firstOperator = value.operators[0];
            setForm({
              name: value.name,
              product_type_code: value.code,
              operator: firstOperator.name,
              operator_code: firstOperator.code,
              amount_min: firstOperator.amount_min,
              amount_max: firstOperator.amount_max,
              amounts: firstOperator.amounts,
              suscriptor_type: firstOperator.suscriptor_type,
            });
          }}
          key={key}
        >
          {value.name}
        </c.Li>
      );
    });
  }

  function renderOperators() {
    let s = products.find((v) => {
      return v.name === form.name;
    });

    if (s !== undefined && s.operators) {
      return Object.entries(s.operators).map(([key, value], i) => {
        return (
          <c.Operator
            key={key}
            selected={form.operator === value.name}
            name={value.code}
            imageURL={value.imageURL}
            onClick={() => {
              setForm({
                ...form,
                operator: value.name,
                operator_code: value.code,
                amount_min: value.amount_min,
                amount_max: value.amount_max,
                amounts: value.amounts,
                packages: value.packages,
                suscriptor_type: value.suscriptor_type,
                pack_code: "",
                amount: "",
              });
              setCodeSelected();
            }}
          ></c.Operator>
        );
      });
    }
  }

  function setDefaultValues(v) {
    let form = {
      name: "Telefonía Móvil",
      product_type_code: "mobile",
      operator: "Entel",
      operator_code: "entel",
      amount: null,
      identifier: "",
      email: "",
      amount_min: 500,
      amount_max: 20000,
      amounts: [],
      suscriptor_type: "PHONE",
    };
    setForm(form);
  }

  const [products, setProducts] = useState([]);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [codeSelected, setCodeSelected] = useState();

  const [state, setState] = useContext(AppContext);

  const history = useHistory();

  function submit() {
    const isValidAmount =
      (fixedAmountsOrPacks && form.amount !== "") ||
      (form.amount !== "" &&
        form.amount >= form.amount_min &&
        form.amount <= form.amount_max);

    setErrors({
      identifier: !validateForm.identifier(
        form.identifier,
        form.suscriptor_type
      ),
      email: !validateForm.email(form.email),
      amount: !isValidAmount,
    });
  }

  useEffect(() => {
    async function fetchData() {
      try {
        let t = await gs.getProducts();

        const products = await Promise.all(
          t.data.map(async (product) => ({
            ...product,
            imageURL: await fetchFirebaseAssets(`products/${product.code}.svg`),
            operators: await Promise.all(
              product.operators.map(async (operator) => ({
                ...operator,
                imageURL: await fetchFirebaseAssets(
                  `operators/${operator.code}.jpeg`
                ),
              }))
            ),
          }))
        );

        console.warn({ products });
        setProducts(products);
      } catch (e) {
        console.log("error gs.getProducts()", e);
      }
    }
    fetchData();
    setDefaultValues();
  }, []);

  useEffect(() => {
    async function validateFormAndGoStep2() {
      if (
        errors.identifier ||
        errors.email ||
        errors.amount ||
        errors.identifier === undefined ||
        errors.email === undefined ||
        errors.amount === undefined
      ) {
        //console.log('Error validate');
      } else {
        let formValidate = {};
        formValidate.operator_code = form.operator_code;
        formValidate.product_type_code = form.product_type_code;
        formValidate.identifier = form.identifier;
        formValidate.amount = form.amount;
        formValidate.package_code = form.pack_code;
        formValidate.email = form.email;

        try {
          let providerInformationId = await gs.transactionValidate(
            formValidate
          );
          setForm({
            ...form,
            provider_information_id: providerInformationId.data,
          });
          history.push("/payment");
        } catch (error) {
          setServerError("Falló verificación de tus datos");
        }
      }
    }
    validateFormAndGoStep2();
  }, [errors]);

  useEffect(
    (v) => {
      setState(form);
    },
    [form]
  );

  function getIdentifierName(f) {
    if (f === undefined) return "";
    if (f.suscriptor_type === "PHONE") return "Número";
    if (f.suscriptor_type === "RUT") return "RUT";
  }

  let fixedAmountsOrPacks =
    form.operator && (form.amounts.length > 0 || form.packages !== undefined);

  function contenido() {
    return (
      <c.FormContainer>
        Recarga todas las compañías, selecciona la tuya:
        <br />
        {renderOperators()}
        <br />
        {form.operator && (
          <>
            {serverError && <c.Error>{serverError}</c.Error>}
            <c.Label>
              <label>{getIdentifierName(form)}</label>
              <br />
              <c.InputText
                type="tel"
                maxLength="9"
                value={form.identifier}
                onChange={(v) =>
                  setForm({ ...form, identifier: v.target.value })
                }
              />
              {errors.identifier && (
                <c.Error>
                  {form.suscriptor_type === "PHONE" &&
                    "Formato incorrecto. Ingresa tu celular en formato 912345678."}
                  {form.suscriptor_type === "RUT" &&
                    "Ingresa tu RUT sin puntos ni guión. (ej:17111000K)"}
                </c.Error>
              )}
              <c.Hint>Obligatorio</c.Hint>
            </c.Label>
            <c.Label>
              <label>Monto</label>
              <br />
              {!fixedAmountsOrPacks && (
                <c.InputText
                  value={form.amount}
                  onChange={(v) =>
                    setForm({
                      ...form,
                      amount: v.target.value ? parseInt(v.target.value) : "",
                    })
                  }
                />
              )}
              {fixedAmountsOrPacks && (
                <c.Select
                  onChange={(event) => {
                    let value = JSON.parse(event.target.value); //object
                    setForm({
                      ...form,
                      amount: value.amount,
                      pack_code: value.pack_code,
                    });
                    setCodeSelected(
                      JSON.stringify({
                        amount: value.amount,
                        pack_code: value.pack_code,
                      })
                    );
                  }}
                  value={codeSelected}
                >
                  <option value={null}>-- Seleccione monto --</option>
                  {form.amounts
                    .sort(function (a, b) {
                      return a - b;
                    })
                    .map(function (item) {
                      return (
                        <option
                          value={JSON.stringify({
                            amount: item,
                            pack_code: "",
                          })}
                        >
                          ${item}
                        </option>
                      );
                    })}

                  {form.packages !== undefined && (
                    <option disabled>-- Compra tu bolsa --</option>
                  )}

                  {form.packages !== undefined &&
                    form.packages.map(function (item) {
                      return (
                        <option
                          value={JSON.stringify({
                            amount: item.amount,
                            pack_code: item.code,
                          })}
                        >
                          ${item.amount} ({item.description})
                        </option>
                      );
                    })}
                </c.Select>
              )}

              {errors.amount && (
                <c.Error>Formato incorrecto. Monto fuera de límites</c.Error>
              )}
              <c.Hint>
                {form !== undefined && form.amount_min !== 0
                  ? "Entre " + form.amount_min + " y " + form.amount_max
                  : ""}
              </c.Hint>
            </c.Label>
            <c.Label>
              <label>Correo electrónico</label>
              <br />
              <c.InputText
                value={form.email}
                onChange={(v) => setForm({ ...form, email: v.target.value })}
              />
              {errors.email && (
                <c.Error>Ingresa tu correo en formato correo@mail.com.</c.Error>
              )}
              <c.Hint>Para enviar tu comprobante</c.Hint>
            </c.Label>
            <c.Button onClick={() => submit()}>Continuar</c.Button>
          </>
        )}
      </c.FormContainer>
    );
  }

  const tabs = [
    { name: renderProducts(products)[1], panel: contenido() },
    { name: renderProducts(products)[0], panel: contenido() },
    { name: renderProducts(products)[2], panel: contenido() },
    { name: renderProducts(products)[3], panel: contenido() },
  ];

  function getTabs() {
    return tabs.map((tab) => ({
      // key: index, // Optional. Equals to tab index if this property is omitted
      tabClassName: "tab", // Optional
      panelClassName: "panel", // Optional
      title: tab.name,
      getContent: () => tab.panel,
    }));
  }

  return (
    <div>
      <Steps steps="1" bg="dark.0" />
      <Container bg="dark.0" size="fluid">
        <c.GralContainer>
          <Tabs items={getTabs()} showMore="false" />
          <c.Form id="form" onSubmit={() => submit()}></c.Form>
        </c.GralContainer>
      </Container>

      <Promociones />
      <FormasPago />
    </div>
  );
}
