import React, { useState, useContext, useEffect, useCallback } from "react";

import styled from "styled-components";
import { Steps } from "../globales/Steps";
import BiColor from "../globales/BiColor";
import { PanelBiColor, OrderTitle, BoxData } from "../globales/PanelBiColor";
import Text from "../globales/Text";
import NavLink from "../globales/NavLink";
import Modal from "../globales/Modal";
import { useModal } from "../../hook/useModal";
import { AppContext } from "../../providers/FormContext";
import gs from "../../components/services/Global";
import { fetchFirebaseAssets, tyc } from "../../utils/storage";
import { logEvents } from "../../utils/analytics-services";
const BUTTON_LOCK_TIME = 5000;

export default function Confirm() {
  const [isOpenModalTyC, openModalTyC, closeModalTyC] = useModal(false);

  const [pago, setPago] = useState("webpay");
  const [payInProgress, setPayInProgress] = useState(false);
  const [webpayData, setWebpayData] = useState({
    url: "",
    token: "",
  });
  const [webpayImageUrl, setWebpayImageUrl] = useState("");
  const [state, setState] = useContext(AppContext);

  const cambioRadioPago = (e) => {
    setPago(e.target.value);
  };

  async function goToPay() {
    if (!payInProgress) {
      setPayInProgress(true);
      console.log("request started");
      let data = {
        amount: state.amount,
        provider: "WEBPAY",
        operator_code: state.operator_code,
        product_type_code: state.product_type_code,
        package_code: state.pack_code,
        identifier: state.identifier,
        email: state.email,
        provider_information_id: state.provider_information_id,
      };
      let webpayResponse = await gs.webpayFinishPyment(data);
      setWebpayData(webpayResponse.data);
      setTimeout(function () {
        setPayInProgress(false);
        console.log("request completed");
      }, BUTTON_LOCK_TIME);
      document.getElementById("redirectionForm").submit();
    } else {
      console.log("request in progress");
    }
  }

  const getWebpayImageURL = useCallback(async () => {
    const imageUrl = await fetchFirebaseAssets("payment-methods/webpay.svg");
    setWebpayImageUrl(imageUrl);
  }, []);

  useEffect(() => {
    logEvents("step_2_payments");
    getWebpayImageURL();
  }, [getWebpayImageURL]);

  return (
    <>
      <Steps steps="2" bg="dark.0" />

      <BiColor height="250px">
        <PanelBiColor bg="light.0" size="small">
          <OrderTitle>
            <Text type="h2" textAlign="center">
              Confirma tu recarga
            </Text>
          </OrderTitle>
          <BoxData>
            <Text type="p">Número a recargar</Text>
            <Text type="p">{state.identifier}</Text>
            <Text type="p">Compañía</Text>
            <Text type="p">{state.operator}</Text>
            <Text type="p">Producto</Text>
            <Text type="p">{state.name}</Text>
            <Text type="p">Correo electrónico</Text>
            <Text type="p">{state.email}</Text>
          </BoxData>
          <OrderTitle>
            <Text type="h2" textAlign="center">
              Selecciona el medio de pago
            </Text>
          </OrderTitle>
          <CheckPago imageURL={webpayImageUrl}>
            <div>
              <span>
                <input
                  id="radio1"
                  type="radio"
                  value="webpay"
                  checked={pago === "webpay" ? true : false}
                  onChange={cambioRadioPago}
                />
                <label className="webpay card" for="radio1"></label>
              </span>
            </div>

            {!payInProgress ? (
              <div
                onClick={() => goToPay()}
                className={payInProgress ? "btnDisable" : "btnEnable"}
              >
                Ir a pagar a ${state.amount} Webpay
              </div>
            ) : (
              <div className={"btnDisable"}>Conectando con Webpay...</div>
            )}
          </CheckPago>
          <form id="redirectionForm" method="post" action={webpayData.url}>
            <input type="hidden" name="token_ws" value={webpayData.token} />
          </form>

          <TerminosYCondiciones>
            <Text>
              Al pagar aceptas los
              <NavLink type="t3" m="5px" onClick={openModalTyC}>
                términos y condiciones
              </NavLink>
              del servicio.
            </Text>
          </TerminosYCondiciones>
        </PanelBiColor>
      </BiColor>

      <Modal isOpen={isOpenModalTyC} closeModal={closeModalTyC}>
        <Text type="titleModal" margin="l" marginTop="xxl">
          {tyc.title}
        </Text>
        <hr />
        <Text type="p" margin="l">
          {tyc.content}
        </Text>
        <Text type="p" margin="l">
          {tyc.footer}
        </Text>
      </Modal>
    </>
  );
}

const TerminosYCondiciones = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

const CheckPago = styled.form`
  width: 100%;
  margin: 20px;
  div {
    max-width: 300px;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
  }
  .webpay {
    ${(props) => `background-image:url(${props.imageURL})`};
    background-size: 140px 180px;
    border: 1px solid #ccc;
  }
  .card {
    cursor: pointer;
    background-repeat: no-repeat;
    display: inline-block;
    width: 140px;
    height: 60px;
    background-position: 0px -61px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px;
    border-radius: 10px;
    &:hover {
      background-position: 0px -7px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    }
  }

  input:active + .card {
    background-position: 0px -116px;
  }
  input:disabled + .card {
    background-position: 0px -61px;
    &:hover {
      border: 1px solid #ccc;
      box-shadow: none;
      box-sizing: border-box;
    }
  }
  input:checked + .card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    background-position: 0 -7px;
  }
  .btnEnable {
    font-size: 12px;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000;
    margin: 5px auto 30px;
    cursor: pointer;
    width: 50%;
    max-width: 250px;
    text-align: center;
    background: #6aff99;
  }
  .btnDisable {
    font-size: 12px;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000;
    margin: 5px auto 30px;
    cursor: pointer;
    width: 50%;
    max-width: 250px;
    text-align: center;
    background: #fff;
  }
`;
