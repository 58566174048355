
import styled from "styled-components";
import * as ss from "styled-system";

// Utils
import type { TextType } from "../../utils/Styled";
import { textCompose } from "../../utils/Styled";

const ContStyled = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  max-width: ${({ size }) =>
    (size === "content" && "1200px") || (size === "fluid" && "100%") || "100%"};

  @media only screen and (max-width: 768px) {
    padding: ${({ size }) =>
      (size === "content" && "10px 20px") ||
      (size === "fluid" && "0px") ||
      "10px 20px"};
  }

  ${ss.compose(
    textCompose,
    ss.variant({
      prop: "type",
      scale: "containerVariants",
    })
  )}
`;

type Props = TextType & {
  type?: string,
};

function Container({ children, type, size, ...rest }: Props): any {
  return (
    <ContStyled type={type} size={size} {...rest}>
      {children}
    </ContStyled>
  );
}

Container.defaultProps = {
  type: "default",
  size: "default",
};

export default Container;
