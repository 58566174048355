// Styled
import styled from "styled-components";
import * as ss from "styled-system";

// Utils
import type { TextType } from '../../utils/Styled';
import { textCompose } from '../../utils/Styled';


const StyledText = styled.div`
  ${ss.compose(
    textCompose,
    ss.variant({
      prop: "type",
      scale: "textVariants",
    })
  )}
`;

type Props = TextType & {
  type?: string,
};

function Text({ children, type, ...rest }: Props): any {
  return (
    <StyledText type={type}  {...rest}>
      {children}
    </StyledText>
  );
}

Text.defaultProps = {
  color: "default",
  type: "default",
  fontFamily: "default",
};

export default Text;