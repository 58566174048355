import styled from "styled-components";
import * as ss from "styled-system";

export const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d8d8d8;
  margin: auto;
  margin-bottom: 30px;
  padding: 0;
  max-width: ${({ size }) =>
    (size === "allWidth" && "100%") ||
    (size === "content" && "1200px") ||
    "100%"};
  ${ss.space}
`;
