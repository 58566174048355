//import env from "react-dotenv";
import Properties from "../../components/Properties";
import axios from "axios";

class GlobalService {
  endpoint = Properties?.backendUrl;
  key = Properties?.getKey;

  getProducts() {
    const { endpoint } = this;
    const { key } = this;
    const serviceUri = "rf-api/product";
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    return axios.get(`${endpoint}${serviceUri}`, headers);
  }

  transactionValidate(data) {
    const { endpoint } = this;
    const { key } = this;
    const serviceUri = "rf-api/transaction/validate";
    const headers = {
      headers: {
        "x-mine-user-id": "f7c4782a-6028-427b-be5c-b59ce44ec425",
        "rf-api-key": `${key}`,
      },
    };
    return axios.post(`${endpoint}${serviceUri}`, data, headers);
  }

  getPayment() {
    const { endpoint } = this;
    const { key } = this;
    const serviceUri = "rf-api/payment/success";
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    return axios.get(`${endpoint}${serviceUri}`, headers);
  }

  webpayFinishPyment(data) {
    const { endpoint } = this;
    const { key } = this;
    const serviceUri = "rf-api/topup";
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    return axios.post(`${endpoint}${serviceUri}`, { ...data }, headers);
  }

  webpayFinishPymentSuccess(token) {
    const { endpoint } = this;
    const { key } = this;
    const serviceUri = "rf-api/payment/voucher/" + token;
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    return axios.get(`${endpoint}${serviceUri}`, headers);
    //const serviceUri = 'https://mocki.io/v1/3208eafc-a30b-4e3b-8287-ce0b615b68f4';
    //return axios.get(`${serviceUri}`);
  }

  webpayFinishPymentError(token) {
    const { endpoint } = this;
    const { key } = this;
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    const serviceUri = "rf-api/payment/voucher/error/" + token;
    return axios.get(`${endpoint}${serviceUri}`, headers);
  }

  goToWebpay(data) {
    const { key } = this;
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    return axios.post(data.url, { token: data.token }, headers);
  }

  contact(data) {
    const { endpoint } = this;
    const { key } = this;
    const serviceUri = "rf-api/ticket";
    const headers = {
      headers: {
        "rf-api-key": `${key}`,
      },
    };
    return axios.post(`${endpoint}${serviceUri}`, data, headers);
  }
}

export default new GlobalService();
