
import type {
  Theme,
  ContainerVariants,
  TextVariant,
  BurgerVariants,
  LinkVariants,

} from "./Theme";

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const colors = {
  default: "#121212",
  primary: ["#6AFF99"],
  light: ["#ffffff", "#f7f7f7"],
  dark: ["#121212", "#212121", "#595959", "#ADADAD", "#C2C2C2"],
  info: ["#3ABDFA", "#7356c8"],
  error: ["#FF6D5A"],
};

const sizes = {
  xxxxxs: "4px",
  xxxxs: "6px",
  xxxs: "8px",
  xxs: "10px",
  xs: "12px",
  s: "14px",
  m: "16px",
  l: "18px",
  xl: "20px",
  xxl: "22px",
  xxxl: "24px",
  xxxxl: "26px",
  xxxxxl: "32px",
};

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Fonts
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const fonts = {
  default: "AmsiProRegular",
  bold: "AmsiProBold",
};

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Text
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const textVariants: TextVariant = {
  default: {
    fontSize: sizes.m,
    color: colors.primary[0],
  },
  small: {
    fontSize: sizes.s,
    textAlign: "center",
  },
  textCenter: {
    color: colors.primary[0],
    fontSize: sizes.m,
    textAlign: "center",
  },
  title: {
    color: colors.primary[0],
    fontSize: sizes.xxxxl,
    fontWeight: "bold",
    paddingTop: 50,
    textAlign: "center",
  },
  titleModal: {
    color: colors.primary[0],
    fontSize: sizes.xxl,
    fontWeight: "bold",
  },
  xs: {
    fontSize: sizes.xs,
  },
  p: {
    fontSize: sizes.s,
  },
  h2: {
    color: colors.primary[0],
    fontSize: sizes.xxxxl,
    fontWeight: "bold",
  },
  h3: {
    color: colors.primary[0],
    fontSize: sizes.xl,
    fontWeight: "bold",
    marginTop: 50,
    marginBottom: 10,
  },
  h4: {
    color: colors.primary[0],
    fontSize: sizes.l,
  },
};
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// NavLink
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const linkVariants: LinkVariants = {
  default: {
    fontSize: sizes.m,
    color: colors.light[0],
    display: "block",
  },
  t1: {
    fontSize: sizes.m,
    color: colors.light[0],
    display: "block",
  },
  t2: {
    fontSize: sizes.m,
    color: colors.primary[0],
    display: "inline-block",
  },
  t3: {
    fontSize: sizes.m,
    color: colors.info[1],
    display: "inline-block",
  },
  buttonPrimary: {
    background: colors.primary[0],
    fontSize: sizes.xs,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 50,
    paddingLeft: 50,
    borderRadius: 5,
    color: colors.dark[0],
    margin: "auto",
    marginTop: 30,
    marginBottom: 30,
  },
};
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Container Variants
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const containerVariants: ContainerVariants = {
  default: {
    justifyContent: "space-between",
    display: "flex",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  FlexNo: {
    display: "block",
  },
};
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Burger Variants
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const burgerVariants: BurgerVariants = {  
  white: {
    background: colors.light[0],
    width: sizes.xxl,
    heigth: sizes.xxxl,
  },
};

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// Export
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const TenpoRF: Theme = {
  lineHeights: sizes,
  fontSizes: sizes,
  space: sizes,
  radii: sizes,
  colors,
  fonts,
  textVariants,
  containerVariants,
  burgerVariants,
  linkVariants,
};

export default TenpoRF;