const { REACT_APP_ENV, REACT_APP_API_KEY_RF_API } = process.env;
let Properties = {
  prod: {
    backendUrl: 'https://prod-internal.tenpo.cl/public/v1/',
    initPaymentUrl: 'rf-api/topup',
    getPaymentUrl: 'rf-payment-api/payment?token=',
    getPaymentUrlFinal: 'rf-payment-api/payment/webpay/final?token=',
    getKey: REACT_APP_API_KEY_RF_API,
  },
  uat: {
    backendUrl: 'https://uat-internal.tenpodigital.cloud/public/v1/',
    initPaymentUrl: 'rf-api/topup',
    getPaymentUrl: 'rf-payment-api/payment?token=',
    getPaymentUrlFinal: 'rf-payment-api/payment/webpay/final?token=',
    getKey: REACT_APP_API_KEY_RF_API,
  },
  dev: {
    backendUrl: 'https://dev.tenpodigital.cloud/public/v1/',
    initPaymentUrl: 'rf-api/topup',
    getPaymentUrl: 'rf-payment-api/payment?token=',
    getPaymentUrlFinal: 'rf-payment-api/payment/webpay/final?token=',
    getKey: REACT_APP_API_KEY_RF_API,
    getEnv: REACT_APP_ENV,
  },
  local: {
    backendUrl: 'http://localhost:8081',
    initPaymentUrl: 'rf-api/topup',
    getPaymentUrl: 'rf-payment-api/payment?token=',
    getPaymentUrlFinal: 'rf-payment-api/payment/webpay/final?token=',
    getKey: '',
  },
};

export default Properties[REACT_APP_ENV];
