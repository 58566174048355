import styled from "styled-components";
import * as ss from "styled-system";

export const PanelBiColor = styled.div`
  max-width: ${({ size }) =>
    (size === "large" && "1200px") ||
    (size === "small" && "750px") ||
    "1200px"};
  margin: auto;
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  padding: 30px 50px;
  flex-wrap: wrap;
  ${ss.color};
  ${ss.space};
  @media only screen and (max-width: 768px) {
    margin: 0 20px;
    padding: 10px;
  }
`;

export const OrderTitle = styled.div`
  width: 100%;  
  @media only screen and (max-width: 768px) {
    margin: 0 20px;
    
  }
`;

export const Order = styled.div`
  background: #6afe99;
  font-size: 40px;
  font-weight: bold;
  margin: auto;
  margin-top: 10px;
  padding: 10px 30px;
  ${ss.typography};
`;

export const BoxData = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 40px 20px;
  margin: 20px 0px;
  ${ss.space};
  ${ss.border};
  div {
    width: 50%;
  }
`;