import React, { useEffect, useState } from "react";
import qs from "qs";

import styled from "styled-components";

import gs from "../../services/Global";

import Container from "../../globales/Container";
import Text from "../../globales/Text";
import BiColor from "../../globales/BiColor.js";
import {Hr} from "../../globales/Hr.js";
import { PanelBiColor, OrderTitle, Order } from "../../globales/PanelBiColor";
import NavLink from "../../globales/NavLink.js";

//Assets
import Sad from "../../../assets/img/payment/Icon-sad.svg";
import {useHistory} from "react-router-dom";

export default function ErrorPayment() {

  const [voucher, setVoucher] = useState({});
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const token = query.get('token');
    const orderBuy = query.get('order');

    async function fetchData() {
      try {
        if(token == null) {
          setVoucher({ order: orderBuy})
        } else {
          let dataVoucher = await gs.webpayFinishPymentError(token);
          setVoucher(dataVoucher.data);
        }
      } catch {
        console.log('error ');

      }
    }
    fetchData();
  }, []);

  return (
      <Container size="fluid" type="FlexNo">
        <Text type="title" bg="dark.0" color="white">
          La transacción fue rechazada
        </Text>
        <BiColor height="250px">
          <Hr size="content"/>
          <PanelBiColor bg="light.0" size="small">
            <OrderTitle>
              <Text type="h4" textAlign="center">
                Número de Orden
              </Text>
              <Order textAlign="center">{voucher.order}</Order>
            </OrderTitle>
            <Lista>
              <img src={Sad} alt="" style={{marginRight: 50}}/>
              <div>
                <Text type="h4" fontFamily="AmsiProBlack" mb="20px">
                  Algunas causas posibles del rechazo son:
                </Text>
                <ul>
                  <li>
                    Error en el ingreso de los datos de tu tarjeta de Crédito o
                    Débito (Fecha y/o código de seguridad).
                  </li>
                  <li>
                    Tu tarjeta de Crédito o Débito no cuenta con el saldo
                    suficiente.
                  </li>
                  <li>Tarjeta aún no habilitada en el sistema financiero.</li>
                </ul>
              </div>
            </Lista>
            <Text type="textCenter">
              Si aparece un cargo erróneo en tu cuenta, será devuelto en un
              plazo máximo de 72 horas hábiles. En caso contrario, comunícate
              con nosotros a través de nuestra página de
              <NavLink type="t2" m="5px" to="/">
                contacto.
              </NavLink>
            </Text>
            <NavLink type="buttonPrimary" to="/">
              Volver a recargar
            </NavLink>
          </PanelBiColor>
        </BiColor>
      </Container>
  );
}

const Lista = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  margin: 50px auto;
`;
