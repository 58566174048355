import React from "react";
import styled from "styled-components";

import Text from "./globales/Text.js";
import Container from "./globales/Container.js";
import { AcordeonItem } from "./globales/AcordeonItem.js";
import  BiColor from "./globales/BiColor.js";
import { PanelBiColor } from "./globales/PanelBiColor.js";
import {Hr} from "./globales/Hr.js";
import { help as dataHelp } from '../utils/storage';

//Assets
import dudasRecarga from '../assets/img/ayuda/Icon-TV.png';

export const Ayuda = () => {
  return (
    <Container size="fluid" type="FlexNo">
      <Text type="title" bg="light.1">
        Te ayudamos
      </Text>
      <BiColor height="120px">
        <Hr size="content" />
        <PanelBiColor bg="light.0" size="large">
          <img
            src={dudasRecarga}
            alt=""
            style={{ marginRight: 25 }}
          />
          Dudas con mi recarga
        </PanelBiColor>
        <AcordeonContent>
          {Object.keys(dataHelp).map((item, i) => (
            <AcordeonItem item={dataHelp[item]} key={i} />
          ))}
        </AcordeonContent>
      </BiColor>
    </Container>
  );
};

const AcordeonContent = styled.div`
    margin-top:20px;
    counter-reset: section;
`;
