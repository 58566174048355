import React, { useState } from "react";
import styled from "styled-components";
import * as ss from 'styled-system';
import Navbar from "./Navbar";

const variant = ss.variant({
  prop: 'type',
  scale: 'burgerVariants',
});


const StyledBurger = styled.div`
  width: 24px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 20;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    ${ss.compose(variant)}
    width: 1.3rem;
    height: 0.14rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
    
  }
`;



const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <StyledBurger type="white" open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Navbar open={open} />
    </React.Fragment>
  );
};

export default Burger;

