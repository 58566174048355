import React, { useState } from "react";
import Chevron from "./Chevron";
import styled from "styled-components";





export const AcordeonItem = ({item}) => {
  
  const [state, setState] = useState("");
  const toggleAccordion = () => {
    setState(state === "" ? "active" : "");
  };



    return (

      <Accordion>
        <AccordionHeading onClick={toggleAccordion}
          className={`other ${state}`}>
          <Header className={`${state}`}>{item.title}</Header>
          <Chevron className={`${state}`} width={20} />
        </AccordionHeading>
        <AccordionContent className={`${state}`}>
          <AccordionText>{item.text}</AccordionText>
          </AccordionContent>
      </Accordion>
    );
}


const Accordion = styled.div`
  max-width:960px;
  margin: auto;
  margin-bottom: -1px;
  width:90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;  
  &:before {    
    display:inline-block;
    counter-increment: section;
    content: '${props => !props.isDeg && " "}' counter(section) '${props => props.isDeg && "° "}';
        color: #6AFE99;
    border-radius: 50%;
    border: 1px solid #6AFE99;
    width: 21px;
    height: 21px;
    font-size: 12px;
    text-align: center;
    line-height: 19px;
  }
  
  
`;

const AccordionHeading = styled.button`
  position: relative;
  display: flex;
  align-items: center !important;
  margin: 0 !important;
  width: 95%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  cursor: pointer;
  text-align: left;
  border: 1px solid transparent;
  border-radius: 0;
  overflow-anchor: none;
  outline: 0 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  &:focus {
    border-color: 0px;
  }
  &.active {
    
  }
  @media only screen and (max-width: 768px) {
       width: 90%;
     }
`;
const Header = styled.h2`
  font-size: 1rem !important;
  margin: 0 !important;
  font-family: "AmsiProBold";
  line-height: 1.2;
  &.active {
  }
`;
const AccordionContent = styled.div`
background-color: white;
  overflow: hidden;
  width: 100%;
  max-height: 0;
  transition: max-height 0.25s ease-in-out;
  &.active {
    max-height: 300px;
  }
  `;
const AccordionText = styled.div`
  font-size: 16px;
  padding:0px 20px 20px 45px;
  `;