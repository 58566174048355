import { createGlobalStyle } from "styled-components";

import AmsiProBlack from "./amsipro-black-webfont.woff";
import AmsiProBlack2 from "./amsipro-black-webfont.woff2";
import AmsiProRegular from "./amsipro-regular-webfont.woff";
import AmsiProRegular2 from "./amsipro-regular-webfont.woff2";
import AmsiProBold from "./amsipro-bold-webfont.woff";
import AmsiProBold2 from "./amsipro-bold-webfont.woff2";
import AmsiProLight from "./amsipro-xlight-webfont.woff";
import AmsiProLight2 from "./amsipro-xlight-webfont.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: 'AmsiProRegular';
        src: local('AmsiProRegular'), local('AmsiProRegular'),
        url(${AmsiProRegular}) format('woff'),
        url(${AmsiProRegular2}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'AmsiProBlack';
        src: local('AmsiProBlack'), local('AmsiProBlack'),
        url(${AmsiProBlack}) format('woff'),
        url(${AmsiProBlack2}) format('woff2');
        font-weight: 700;
        font-style: black;
    }
    
    @font-face {
        font-family: 'AmsiProBold';
        src: local('AmsiProBold'), local('AmsiProBold'),
        url(${AmsiProBold}) format('woff'),
        url(${AmsiProBold2}) format('woff2');
        font-weight: 600;
        font-style: bold;
    }
    @font-face {
        font-family: 'AmsiProLight';
        src: local('AmsiProLight'), local('AmsiProLight'),
        url(${AmsiProLight}) format('woff'),
        url(${AmsiProLight2}) format('woff2');
        font-weight: 300;
        font-style: normal;
    }
`;
