import styled from "styled-components";


export const NavbarWrapper = styled.ul`
  z-index:999;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;  
  a{
      color:#fff;
      text-decoration:none;
      font-size:14px;
      li {
        margin: 18px 20px;    
        border-bottom: 2px solid transparent;
        @media (max-width: 768px) { 
          width:40px
        }    
      }
      @media (max-width: 768px) { 
        color:#000;
        font-size:18px;
      }
    }
  .current {
    li {
      border-bottom: 2px solid #6afe99;
      @media (max-width: 768px) { 
        border-bottom: 2px solid #fff;
      }
    }
  }

  
  @media (max-width: 768px) {    
    flex-flow: column nowrap;  
    display: ${({ open }) => open ? 'flex' : 'none'};
    position:absolute;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    background:#6AFF99;
    top: 81.61px;
    right: 0;
    height: 100vh;
    width: 100%;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;

