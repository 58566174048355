import styled from "styled-components";
import c from "../../../utils/Colors";

export const GralContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: auto;
  background: ${c.white01};
  border-radius: 7px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
  border: 1px solid ${c.gray04};
  box-sizing: content-box;
  overflow: hidden;
  @media only screen and (max-width: 1190px) {
    margin: 0px 15px;
  }
  .RRT__container {
    display: flex;
    @media only screen and (max-width: 846px) {
      display: block;
    }
  }
  .RRT__tabs {
    max-width: 261px;
    @media only screen and (max-width: 846px) {
      max-width: 100%;
    }
  }
  .tab {
    width: 100%;
    border-width: 1px 1px 2px 0px;
    border-color: ${c.white} ${c.gray04} ${c.gray04} ${c.white01};
    background: transparent;
    padding: 0;
    display: flex;
    &:hover {
      border-color: ${c.gray04} ${c.white01} ${c.green01} ${c.white};
    }
    @media only screen and (max-width: 846px) {
      border-width: 1px 0px 2px 0px;
    }
  }
  .RRT__tab--selected {
    border-color: ${c.gray04} ${c.white01} ${c.green01} ${c.white};
  }
  .RRT__tab--first {
    border-top-color: transparent;
    &:hover {
      border-top-color: transparent;
    }
  }
  .RRT__panel {
    padding: 0;
    border: 0;
    max-width: 880px;
    width: 100%;
    min-height: 252px;
    border-left: 0px;
    vertical-align: top;
    padding: 15px;
`;

export const Hint = styled.div`
  width: 180px;
  color: ${c.gray03};
  font-size: 12px;
  text-align: right;
  margin-top: 2px;
  @media only screen and (max-width: 846px) {
    width: 100%;
  }
`;

export const Error = styled.div`
  width: 180px;
  color: ${c.red01};
  font-size: 11px;
  text-align: left;
  margin-top: 2px;
  @media only screen and (max-width: 846px) {
    width: 100%;
  }
`;

export const Operator = styled.div`
  width: 50px;
  height: 55px;
  display: inline-block;
  margin: 16px 10px;
  background-repeat: no-repeat;
  background-position: center top;
  cursor: pointer;
  &:nth-of-type(1) {
    margin-left: 0px;
  }
  @media only screen and (max-width: 600px) {
    margin: 10px 20px 10px 0px;
  }
  ${({ selected }) =>
    selected &&
    "background-position: center -63px; border-bottom:2px solid " +
      c.green01 +
      ";"};
  ${(props) => `background-image:url(${props.imageURL})`};
`;

export const Label = styled.div`
  width: 180px;
  display: inline-block;
  margin-right: 20px;
  height: 87px;
  vertical-align: top;
  label {
    font-weight: bold;
    font-size: 14px;
  }
  @media only screen and (max-width: 846px) {
    width: 100%;
  }
`;

export const Select = styled.select`
  padding: 15px 10px;
  width: 180px;
  border: 1px solid ${c.gray01};
  border-radius: 11px;
  background: ${c.gray02};
  &:focus {
    border: 1px solid ${c.green01};
    background: ${c.white01};
    color: ${c.black01};
    outline-width: 0;
  }
  @media only screen and (max-width: 846px) {
    width: 100%;
  }
`;

export const InputText = styled.input`
  padding: 15px 10px;
  width: 180px;
  border: 1px solid ${c.gray01};
  border-radius: 11px;
  background: ${c.gray02};
  &:focus {
    border: 1px solid ${c.green01};
    background: ${c.white01};
    color: ${c.black01};
    outline-width: 0;
  }
  @media only screen and (max-width: 846px) {
    width: 100%;
  }
`;

export const Button = styled.div`
  padding: 15px 10px;
  width: 170px;
  border-radius: 5px;
  border: 0px;
  background: ${c.green01};
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  vertical-align: top;
  margin-top: 22px;
  @media only screen and (max-width: 846px) {
    margin: 30px auto;
    display: block;
  }
`;

export const FormContainer = styled.div``;
export const Form = styled.form`
  display: flex;
`;

export const Ul = styled.ul`
  width: 260px;
  display: flex;
  flex-wrap: wrap;
`;
export const Li = styled.li`
  padding: 0px 10px;
  width: 100%;
  color: #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #000;
    font-weight: bold;
    &:before {
      background-position: 0px 40px;
    }
  }
  @media only screen and (max-width: 846px) {
    padding: 10px;
  }

  ${({ selected }) =>
    (selected && "color: " + c.black01 + "; font-weight: bold;") || ""};

  &:before {
    width: 48px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    content: "";
    background-position: 0px 0px;
    ${(props) => `background:url(${props.imageURL})`};

    ${({ selected }) => selected && "background-position: 0px 40px;"};
  }
`;

export const ContFinish = styled.div`
  max-width: 600px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const FormContact = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 34px;
  background: #fff;
  margin-top: 24px;
  margin-bottom: 34px;
  max-width: 746px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
  border: 1px solid #d8d8d8;
  text-align: left;
`;

export const FormContactHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

export const SimpleContact = styled.div`
  text-align: center;
  margin-bottom: 24px;
  max-width: 700px;
  margin: 0 auto;
  padding: 50px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${c.black01};
  font-weight: bold;
`;

export const ContactTitle = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: bolder;
  background: transparent;
  padding-top: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d8d8d8;
`;
export const Gray = styled.div`
  text-align: center;
  background: #f7f7f7;
`;

export const LabelContact = styled.div`
  font-weight: bolder;
  color: #000;
  margin-bottom: 6px;
  margin-top: 24px;
`;
export const InputTextContact = styled.input`
  padding: 15px 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 11px;
  box-shadow: none !important;
  background: #f0f0f0;
  &:focus {
    border: 1px solid ${c.green01};
    background: ${c.white01};
    color: ${c.black01};
    outline-width: 0;
  }
`;

export const ButtonContact = styled.div`
  padding: 15px 10px;
  width: 170px;
  border-radius: 5px;
  border: 0px;
  background: ${c.green01};
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  vertical-align: top;
  margin: 0 auto;
  margin-top: 22px;
  @media only screen and (max-width: 846px) {
    margin: 30px auto;
    display: block;
  }
`;

export const SelectContact = styled.select`
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${c.gray01};
  border-radius: 11px;
  background: ${c.gray02};
  &:focus {
    border: 1px solid ${c.green01};
    background: ${c.white01};
    color: ${c.black01};
    outline-width: 0;
  }
`;

export const TextContact = styled.textarea`
  display: block;
  resize: vertical;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 11px;
  box-shadow: none !important;
  background: #f0f0f0;
  height: 130px;
  &:focus {
    border: 1px solid ${c.green01};
    background: ${c.white01};
    color: ${c.black01};
    outline-width: 0;
  }
`;

export const RequiredFields = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const Captcha = styled.div`
  text-align: center;
  width: 310px;
  margin: 0 auto;
`;
export const Success = styled.div`
  background: ${c.green02};
  height: 130px;
  width: 100%;
  margin: 0 auto;
  padding: 34px;
  border-radius: 11px;
  max-width: 746px;
  margin-top: 40px;
  margin-bottom: 140px;
`;
export const SuccessTitle = styled.div`
  font-weight: bold;
`;
export const SuccessText = styled.div``;
export const SuccessClose = styled.div`
  font-size: 22px;
  color: gray;
  text-align: right;
  margin-top: -20px;
  margin-right: -15px;
  cursor: pointer;
`;
