// React
import React, { useState } from "react";

// Styled
import styled from "styled-components";
import { color } from "styled-system";

//Components
import MenuButton from "./Burger";
import Container from "./globales/Container.js";
import { Logo } from "./globales/Logo.js";
import NavLink from "./globales/NavLink";

//Assets
import imglogo from "../assets/img/logo-tenpo-rf.svg";
import { useModal } from "../hook/useModal";

export const HeaderScreen = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [isOpenModalTyC, openModalTyC, closeModalTyC] = useModal(true);

  return (
    <>
      <HeaderWrapper bg="dark.0">
        <Container size="content">
          <NavLink to="/" exact>
            <Logo size="header" src={imglogo} alt="Tenpo Recarga Fácil" />
          </NavLink>
          <MenuButton open={open} handleClick={handleClick} />
        </Container>
      </HeaderWrapper>
    </>
  );
};

const HeaderWrapper = styled.header`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  ${color}

  @media only screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;
