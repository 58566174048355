import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import styled from "styled-components";
import BiColor from "./globales/BiColor";

export const Promociones = () => {
  const [promo, setPromo] = useState(false);
  const getCollection = async () => {
    const db = getFirestore();
    const data = await getDocs(collection(db, "banner"));

    let allDocs = [];
    data.forEach((doc) => {
      console.log("doc:: ", doc.data());
      allDocs.push(doc.data());
    });
    setPromo(allDocs);
  };

  useEffect(() => {
    getCollection();
  }, []);

  return (
    <BiColor height="205px">
      <ContPromo>
        {promo &&
          promo.map((promociones) => (
            <ImgCont key={promociones.id}>
              <a href={promociones.link}>
                <img src={promociones.image} alt="" />
              </a>
            </ImgCont>
          ))}
      </ContPromo>
    </BiColor>
  );
};

const ContPromo = styled.div`
  display: flex;
  max-width: 1140px;
  margin: auto;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ImgCont = styled.div`
  padding: 15px;
  box-sizing: content-box;
  img {
    width: 100%;
    max-width: 360px;
  }
`;
