import styled from "styled-components";
import { Link } from "react-router-dom";

import * as ss from "styled-system";

// Utils
import type { TextType } from "../../utils/Styled";
import { textCompose } from "../../utils/Styled";


const StyledNav = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  ${ss.compose(
    textCompose,
    ss.variant({
      prop: "type",
      scale: "linkVariants",
    })
  )}
`;

type Props = TextType & {
  type?: string,
};

function NavLink({ children, type, ...rest }: Props): any {
  if (type === 'url')
  {
    return (<StyledNav type="t1" onClick={()=> window.location.href='https://tenpo.cl/quienes-somos' }>{children}</StyledNav>);

  }else{
      return (
    <StyledNav type={type} {...rest}>
      {children}
    </StyledNav>
  );
  }

}
NavLink.defaultProps = {
  type: "default",
};


export default NavLink;