import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import TenpoRF from './themes/ThemeRF.js';

import Recarga from './components/Recarga.js';
import { HeaderScreen } from './components/Header.js';
import Contacto from './components/Contacto.js';
import { Ayuda } from './components/Ayuda.js';
import { Footer } from './components/Footer.js';
import { QuienesSomos } from './components/QuienesSomos.js';
import Confirm from './components/payment/Confirm';
import FinishPayment from './components/payment/webpay/WebpayFinishPayment';
import WebpayPaymentVoucher from './components/payment/webpay/WebpayPaymentVoucher';
import ErrorPayment from './components/payment/webpay/WebpayErrorPayment';

import GlobalFonts from './assets/fonts/font';
import FormContext from './providers/FormContext';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { logEvents } from './utils/analytics-services';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'AmsiProRegular';
`;

const Main = styled.div`
  background: #fff;
  flex: 1;
`;

function App() {
  const fpPromise = FingerprintJS.load();

  (async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    //console.log('visitorId', visitorId);
  })();

  const [locked, setLocked] = useState(false);

  useEffect(()=>{
    logEvents('step_1_home_rf')
  },[])

  return (
        <ThemeProvider theme={TenpoRF}>
          <Router>
            <Wrapper>
              <GlobalFonts />
              <HeaderScreen />
              <FormContext>
                <Main>
                  <Route exact path="/" component={Recarga} />
                  <Route
                    exact
                    path="/contacto"
                    component={Contacto}
                  />
                  <Route exact path="/ayuda" component={Ayuda} />
                  <Route
                    exact
                    path="/quienes-somos"
                    component={QuienesSomos}
                  />
                  <Route exact path="/payment" component={Confirm} />
                  <Route
                    exact
                    path="/paymentVoucher"
                    component={WebpayPaymentVoucher}
                  />
                  <Route
                    exact
                    path="/finishPayment"
                    component={FinishPayment}
                  />
                  <Route
                    exact
                    path="/errorPayment"
                    component={ErrorPayment}
                  />
                </Main>
              </FormContext>

              <Footer />
            </Wrapper>
          </Router>
        </ThemeProvider>
    
  );
}

export default App;
