import styled from "styled-components";
import * as ss from "styled-system";


interface Props {
  height: String;
}


function BiColor({ children, height, type, ...rest }: Props): any {
  return (
    <ContBiColor height={height} {...rest}>
      {children}
    </ContBiColor>
  );
};

const ContBiColor = styled.div`
  margin: auto;
  padding: 30px 0;
  overflow: visible;
  background:#121212;
  position:relative;
  ${ss.size}
  ${ss.color}
  z-index:1;
  &:after{
    content:"";
    height: ${(props) => props.height};
    background:#121212;
    width: 100%;
    position: absolute;
    top: 0;  
    z-index:-1; 
      
  }

  @media (max-width: 768px) {}
`;

export default BiColor;