import React from 'react'
import styled from "styled-components";

const BoxModal = styled.article`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: ${({isOpen }) => (isOpen ? "flex" : "none")};
`;
const ModalContainer = styled.div`
  position: relative;
  background: #fff;
  min-width: 320px;
  max-width: 748px;
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
  padding: 1rem;
  margin: 20px auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
`;
const ModalClose = styled.button`
  font-size: 50px;
  font-weight: 400;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 15px;
  background: transparent;
  border: none;
  color: #000;
  opacity: 0.3;
  &:hover,
  &:focus {
    opacity: 0.5;
  }
`;

function Modal({ children, isOpen, closeModal}){
    const handleModalContinerClick = e => e.stopPropagation();
  return (
    <BoxModal isOpen={isOpen} onClick={closeModal}>
      <ModalContainer onClick={handleModalContinerClick}>
        <ModalClose onClick={closeModal}>×</ModalClose>
        {children}        
      </ModalContainer>
    </BoxModal>
  );
}

export default Modal;