import { createContext, useState } from "react";

const FormContext =(props)=>{
  const [state,setState] = useState({});    
  return(
    <div>
      <AppContext.Provider value={[state,setState]}>
        {props.children}
      </AppContext.Provider>
    </div>
  );
}



export default FormContext;
export const AppContext = createContext();