/**
 * @format
 * @flow
 */
import * as ss from "styled-system";

/*
  $FlowIgnore[value-as-type] Flow doesn't support block ignoring.
  Ignoring because of how StyledSystem types are defined.
*/
export type ComponentType = ss.SpaceProps &
  // $FlowIgnore[value-as-type]
  ss.BorderProps &
  // $FlowIgnore[value-as-type]
  ss.ColorProps &
  // $FlowIgnore[value-as-type]
  ss.LayoutProps &
  // $FlowIgnore[value-as-type]
  ss.FlexboxProps &
  // $FlowIgnore[value-as-type]
  ss.PositionProps &
  // $FlowIgnore[value-as-type]
  ss.ShadowProps;

export const componentCompose: any = ss.compose(
  ss.space,
  ss.border,
  ss.color,
  ss.layout,
  ss.flexbox,
  ss.position,
  ss.shadow
);

// $FlowIgnore[value-as-type]
export type TextType = ss.SpaceProps &
  // $FlowIgnore[value-as-type]
  ss.BorderProps &
  // $FlowIgnore[value-as-type]
  ss.ColorProps &
  // $FlowIgnore[value-as-type]
  ss.LayoutProps &
  // $FlowIgnore[value-as-type]
  ss.FlexboxProps &
  // $FlowIgnore[value-as-type]
  ss.PositionProps &
  // $FlowIgnore[value-as-type]
  ss.ShadowProps &
  // $FlowIgnore[value-as-type]
  ss.TypographyProps;

export const textCompose: any = ss.compose(
  ss.space,
  ss.border,
  ss.color,
  ss.layout,
  ss.flexbox,
  ss.position,
  ss.shadow,
  ss.typography
);
