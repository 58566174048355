import React from 'react'
import styled from "styled-components";
import * as ss from "styled-system";

const Progress = styled.div`
  text-align: center;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  ${ss.color}
  @media only screen and (max-width: 768px) {
    padding-top: 25px;
    padding-bottom: 30px;
  }
`;

const Circle = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  vertical-align: top;
  border: 1.5px solid #d8d8d8;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  ${({ steps }) =>
  (steps >= 1) && "&:nth-of-type(1) {background: #6afe99; border-color: #000; label {color: #000};}"};

  ${({ steps }) =>
   (steps >= 2) && "&:nth-of-type(2) { background: #6afe99; border-color: #000; label {color: #000};}"};

  ${({ steps }) =>
   (steps >= 3) && "&:nth-of-type(3) { background: #6afe99; border-color: #000; label {color: #000};}"};

`;

const Bar = styled.span`
  display: inline-block;
  background: #d8d8d8;
  position: relative;
  max-width: 125px;
  height: 1.5px;
  margin: 0px 2px 0px 2px;
  top: 20px;
  vertical-align: top;
  width: 20%;
  @media only screen and (max-width: 768px) {
    top: 15px;
    margin: -1px 1px 17px 1px;
  }
  &:nth-of-type(1) {
    background: ${({ steps }) =>
      (steps === "2" && "#6afe99") || (steps === "3" && "#6afe99") || ""};
  }
  &:nth-of-type(2) {
    background: ${({ steps }) =>
      (steps === "2" && "") || (steps === "3" && "#6afe99") || ""};
  }
`;

const Label = styled.label`
  display: inline-block;
  width: 32px;
  line-height: 24px;
  border-radius: 32px;
  margin-top: 7px;
  color: #adadad;
  font-size: 18px;
  @media only screen and (max-width: 768px) {
    width: 24px;
    font-size: 16px;
    line-height: 13px;
  }
`;

const Title = styled.p`
  color: #adadad;
  font-size: 12px;
  line-height: 24px;
  margin-left: -30px;
  display: block;
  width: 100px;
  margin-top: 10px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

interface Props {
  steps: '1' | '2' | '3',
}




export const Steps = ({ steps,  ...rest}: Props): any  => {


     return (
       <Progress steps={steps} {...rest}>
         <Circle steps={steps}>
           <Label>1</Label>
           <Title>Recarga</Title>
         </Circle>
         <Bar steps={steps} />
         <Circle steps={steps}>
           <Label>2</Label>
           <Title>Confirma</Title>
         </Circle>
         <Bar steps={steps} />
         <Circle steps={steps}>
           <Label>3</Label>
           <Title>Éxito</Title>
         </Circle>
       </Progress>
     );
}

