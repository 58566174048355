// eslint-disable-next-line import/no-anonymous-default-export
export default {
  green01: '#6afe99',
  green02: '#B7FCC4',
  gray01: '#ccc', // border
  gray02: '#f0f0f0', // background input disable
  gray03: '#adadad', // hint
  gray04: '#d8d8d8', // hint
  white01: '#fff', // background input enable
  black01: '#000',
  red01: '#d30c0c',
};
