import styled from "styled-components";

export const Logo = styled.img`
  width:${({ size }) =>
        (size === "header" && "230px") || (size === "footer" && "150px") || "100%"};
  @media only screen and (max-width: 768px) {
    width:${({ size }) =>
        (size === "header" && "130px") || (size === "footer" && "120px") || "100%"};
  }
`;

