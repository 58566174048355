import React from 'react';
import qs from 'qs';
import $ from 'jquery';
import Properties from '../../Properties'

export default class WebpayPaymentVoucher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token,
      url: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).url
    }
  }

  componentDidMount() {
    let self = this;
    $("#redirectionForm").submit();    
  }

  render() {
    return (
      <div>
        <form id="redirectionForm" method="post" action={this.state.url}>
          <input type="hidden" name="token_ws" value={this.state.token} />
        </form>
      </div>   
    );
  }
}