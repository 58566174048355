import React from 'react'
import { NavLink } from "react-router-dom";
import { NavbarWrapper } from './globales/NavbarStyles';



function Navbar({ open }) {
  return (
    <NavbarWrapper open={open} >
        <NavLink to="/" activeClassName="current" exact>
          <li>Recarga</li>
        </NavLink>
        <NavLink to="/ayuda" activeClassName="current" exact>
          <li>Ayuda</li>
        </NavLink>
        <NavLink to="/contacto" activeClassName="current" exact>
          <li>Contacto</li>
        </NavLink>
    </NavbarWrapper>
  );
}

export default Navbar;