import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '../../globales/Text';
import BiColor from '../../globales/BiColor.js';
import { Hr } from '../../globales/Hr.js';
import {
  PanelBiColor,
  OrderTitle,
  BoxData,
} from '../../globales/PanelBiColor';
import NavLink from '../../globales/NavLink.js';
import { Steps } from '../../globales/Steps';
import { ContFinish } from '../../globales/Recarga/Styles';
import gs from './../../../components/services/Global';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import { logEvents } from '../../../utils/analytics-services';

export default function FinishPayment() {
  const [voucher, setVoucher] = useState({});
  const history = useHistory();

  useEffect(() => {
    logEvents('step_3_success_payment')
    const query = new URLSearchParams(history.location.search);
    const token = query.get('token');

    async function fetchData() {
      try {
        let dataVoucher = await gs.webpayFinishPymentSuccess(token);
        if (
          dataVoucher.status === 200 &&
          dataVoucher.data.webpay_result.details != null
        ) {
          console.log('dataVoucher.status === 200');
          setVoucher(dataVoucher.data);
        } else {
          console.log('error ');
          history.push({
            pathname: '/errorPayment',
            search: '?token=' + token,
          });
        }
      } catch {
        console.log('error ');
        history.push({
          pathname: '/errorPayment',
          search: '?token=' + token,
        });
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {voucher.identifier && (
        <>
          <Steps steps="3" bg="dark.0" />
          <BiColor height="250px">
            <PanelBiColor bg="light.0" size="small" pl="0" pr="0">
              <OrderTitle>
                <Text
                  type="h2"
                  mb="20px"
                  fontFamily="AmsiProBlack"
                  textAlign="center"
                >
                  ¡Felicitaciones!
                </Text>
                <Hr mb="15px" />
              </OrderTitle>
              <ContFinish>
                <OrderTitle>
                  <Text type="h4">Detalle de tu recarga</Text>
                </OrderTitle>
                <BoxData mt="15px" mb="15px">
                  <Text type="p">Compañía</Text>
                  <Text type="p">
                    {voucher.product_operator.operator.name}
                  </Text>
                  <Text type="p">Número</Text>
                  <Text type="p">{voucher.identifier}</Text>
                  <Text type="p">Producto</Text>
                  <Text type="p">
                    {voucher.product_operator.product.name}
                  </Text>
                  <Text type="p">Orden de compra</Text>
                  <Text type="p">{voucher.order}</Text>
                  <Text type="p">Código autorización</Text>
                  <Text type="p">{voucher.authorization_code}</Text>
                </BoxData>
                <OrderTitle>
                  <Text type="h4">Detalle del medio de pago</Text>
                </OrderTitle>
                {voucher.webpay_result && (
                  <BoxData mt="15px" borderBottom="0px">
                    <Text type="p">Medio de Pago</Text>
                    <Text type="p">Webpay Plus</Text>
                    <Text type="p">Número de Cuotas</Text>
                    <Text type="p">
                      {voucher.webpay_result.details[0].shares_number}
                    </Text>
                    <Text type="p">Tipo de Cuotas</Text>
                    <Text type="p">
                      {
                        voucher.webpay_result.details[0]
                          .payment_type_code
                      }
                    </Text>
                    <Text type="p">
                      Últimos cuatro dígitos de la tarjeta
                    </Text>
                    <Text type="p">
                      {voucher.webpay_result.card_number}
                    </Text>
                    <Text type="p">Código Autorización</Text>
                    <Text type="p">
                      {
                        voucher.webpay_result.details[0]
                          .authorization_code
                      }
                    </Text>
                    <Text type="p">Fecha</Text>
                    <Text type="p">
                      {Moment(
                        voucher.webpay_result.transaction_date,
                      ).format('D/MM/YYYY')}
                    </Text>
                    <Text type="p">Hora</Text>
                    <Text type="p">
                      {Moment(
                        voucher.webpay_result.transaction_date,
                      ).format('H:mm')}
                    </Text>
                  </BoxData>
                )}

                <NavLink
                  alignSelf="center"
                  type="buttonPrimary"
                  to="/"
                >
                  Volver a recargar
                </NavLink>
              </ContFinish>
            </PanelBiColor>
          </BiColor>
          {/*}   <Modal isOpen={isOpenModalEncuesta} closeModal={closeModalEncuesta}>

            <Text type="titleModal">Encuesta</Text>

            <hr />
            <ContWidget>
              <Widget id={`FGW8nJ#correo=${voucher.email}`} className="typeformRF" />
            </ContWidget>
          </Modal>
                */}
        </>
      )}
    </>
  );
}
const ContWidget = styled.div`
  display: block;
  width: 700px;
  height: 90%;
  text-align: center;
  .typeformRF {
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      max-height: 600px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
